import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const Jugend = ({ data }) => (
    <InnerPage title="Jugend" description="">
        <IntroHolder
            title="Jugend"
            subtitle="Junge Menschen zeigen Initiative und Verantwortung für ihr Umfeld"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Gemeinsames Handeln',
                'current-item': 'Jugend',
            }}
        >
            <IntroQuote href="/gemeinsames-handeln/jugend/zitate">
                Obwohl eure Lebenswirklichkeiten von den unterschiedlichsten
                Umständen geprägt sind, ist doch der Wunsch, eine konstruktive
                Veränderung zu bewirken und die Fähigkeit zu sinnvollem Dienst
                zu entwickeln – beides charakteristisch für Eure Lebensphase –,
                weder durch Rasse oder Nationalität begrenzt noch von
                materiellen Mitteln abhängig.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Die Jugend war schon immer bereit, sich für
                    gesellschaftliche Veränderungen zu engagieren. Damit sie ihr
                    Potenzial entfalten kann, benötigt sie geistig-spirituelles
                    und intellektuelles Wachstum.
                </p>
                <div>
                    <p>
                        Jugendliche durchleben in diesem besonderen
                        Lebensabschnitt viele Veränderungen und treffen
                        Entscheidungen, welche die Weichen für ihr weiteres
                        Leben stellen. Dazu gehören die Wahl eines Berufs, erste
                        Schritte im eigenen Job sowie Partnerschaft und
                        Familiengründung. Nicht weniger wichtig sind die
                        verschiedenen Möglichkeiten, einen positiven Beitrag zur
                        Förderung der Gesellschaft zu leisten. Energie,
                        Ernsthaftigkeit und Abenteuerlust junger Menschen können
                        eine Dynamik freisetzen, die auch andere Mitglieder der
                        Gesellschaft zum Mitmachen einlädt. Gemeinschaft,
                        gegenseitige Ermutigung und die Bereitschaft,
                        voneinander zu lernen, stärken jede Gruppe von
                        Jugendlichen, die nach einem gemeinsamen Ziel strebt.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        In unserer Gesellschaft finden sich vielfältige
                        Möglichkeiten des Engagements. Jeder hat dabei die
                        Chance, sein eigenes Potenzial in den Dienst der
                        Gesellschaft zu stellen und damit zu ihrer
                        Weiterentwicklung beizutragen.
                    </p>
                    <p>
                        Für Jugendliche ergeben sich dabei wertvolle
                        Gelegenheiten, andere Jugendliche, jünger als sie
                        selbst, miteinzubeziehen – in sogenannten{' '}
                        <Link to="/gemeinsames-handeln/jugend/arbeit-mit-juniorjugendlichen/">
                            Juniorjugendgruppen
                        </Link>
                        . Gemeinsam schmieden sie Pläne, wie sie ihre Ideen in
                        die Tat umsetzen und ihr Umfeld aktiv mitgestalten
                        können.
                    </p>
                </div>
                <Reference>
                    <p>1. Das Universale Haus der Gerechtigkeit, Ridvan 2010</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default Jugend;

export const query = graphql`
    query {
        introImage: file(relativePath: { eq: "jugend-feature.jpg" }) {
            ...fluidImage
        }
        collage: file(relativePath: { eq: "jugend-img-1.jpg" }) {
            ...fluidImage
        }
    }
`;
